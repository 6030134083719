import React, { useMemo } from "react";
import "./App.css";
import { BlockRenderer } from "@chaibuilder/react";

function App() {
  const content = useMemo(
    () => [
      {
        type: "Box",
        id: "AAluoA",
        classes: "bg-slate-900",
        tag: "div",
        parent: null,
      },
      {
        type: "Box",
        id: "xrCrAq",
        classes: "bg-gradient-to-b from-violet-600/[.15] via-transparent",
        tag: "div",
        parent: "AAluoA",
      },
      {
        type: "Box",
        id: "azBbql",
        classes: "max-w-[85rem] mx-auto px-4 sm:px-6 lg:px-8 py-24 space-y-8",
        tag: "div",
        parent: "xrCrAq",
      },
      {
        type: "Box",
        id: "mpqccr",
        classes: "max-w-3xl text-center mx-auto",
        tag: "div",
        parent: "azBbql",
      },
      {
        type: "Heading",
        id: "fDonrb",
        classes:
          "block font-medium text-gray-200 text-4xl sm:text-5xl md:text-6xl lg:text-7xl",
        content: "Now it's easier than ever to offer subscriptions",
        level: 1,
        parent: "mpqccr",
      },
      {
        type: "Box",
        id: "BcrblD",
        classes: "max-w-3xl text-center mx-auto",
        tag: "div",
        parent: "azBbql",
      },
      {
        type: "Text",
        id: "plbfgk",
        classes: "text-lg text-gray-400",
        content:
          "It's about time for South African Shopify merchants to stop missing out on recurring revenue",
        parent: "BcrblD",
      },
      {
        type: "Box",
        id: "Chzbby",
        classes: "text-center",
        tag: "div",
        parent: "azBbql",
      },
      {
        type: "Link",
        id: "bDtxCk",
        classes:
          "inline-flex justify-center items-center gap-x-3 text-center bg-gradient-to-tl from-blue-600 to-violet-600 shadow-lg shadow-transparent hover:shadow-blue-700/50 border border-transparent text-white text-sm font-medium rounded-full focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 focus:ring-offset-white py-3 px-6 dark:focus:ring-offset-gray-800",
        link: "mailto:wattpay@develocks.dev",
        parent: "Chzbby",
      },
      {
        type: "Span",
        id: "ADiyjk",
        content: "Get WattPay today",
        parent: "bDtxCk",
      },
      {
        type: "SVG",
        id: "pldDdv",
        classes: "w-2.5 h-2.5",
        svg: '<svg class="w-2.5 h-2.5" width="16" height="16" viewBox="0 0 16 16" fill="none">\t\t\t\t\t\t<path d="M5.27921 2L10.9257 7.64645C11.1209 7.84171 11.1209 8.15829 10.9257 8.35355L5.27921 14" stroke="currentColor" stroke-width="2" stroke-linecap="round"></path>\t\t\t\t\t</svg>',
        parent: "bDtxCk",
      },
      {
        type: "Box",
        id: "fymlrn",
        classes: "max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto",
        tag: "div",
        parent: null,
      },
      {
        type: "Box",
        id: "seiswn",
        classes:
          "grid items-center gap-2 sm:grid-cols-2 lg:grid-cols-4 xl:grid-cols-3",
        tag: "div",
        baseClasses: "",
        dynamicClasses: "",
        parent: "fymlrn",
      },
      {
        type: "Link",
        id: "yexCxB",
        classes:
          "group flex flex-col justify-center hover:bg-gray-50 rounded-xl p-4 md:p-7 dark:hover:bg-slate-800",
        link: "#",
        parent: "seiswn",
      },
      {
        type: "Box",
        id: "dvCCdr",
        classes:
          "flex justify-center items-center w-12 h-12 bg-blue-600 rounded-xl",
        tag: "div",
        parent: "yexCxB",
      },
      {
        type: "SVG",
        id: "skDBjA",
        classes: "w-6 h-6 text-white",
        svg: '<svg class="w-6 h-6 text-white" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">\t\t\t\t\t<path d="M11 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h6zM5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H5z"></path>\t\t\t\t\t<path d="M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"></path>\t\t\t\t</svg>',
        parent: "dvCCdr",
      },
      {
        type: "Box",
        id: "yghlxz",
        classes: "mt-5",
        tag: "div",
        parent: "yexCxB",
      },
      {
        type: "Heading",
        id: "xpxmxt",
        classes:
          "group-hover:text-gray-600 text-lg font-semibold text-gray-800 dark:text-white dark:group-hover:text-gray-400",
        content: "Seamless",
        level: 3,
        parent: "yghlxz",
      },
      {
        type: "Text",
        id: "frjpmc",
        classes: "mt-1 text-gray-600 dark:text-gray-400",
        content:
          "We do not change your checkout process at all. A block gets added to relevant products that allows subscription setup",
        parent: "yghlxz",
      },
      {
        type: "Link",
        id: "Cekszq",
        classes:
          "group flex flex-col justify-center hover:bg-gray-50 rounded-xl p-4 md:p-7 dark:hover:bg-slate-800",
        link: "#",
        parent: "seiswn",
      },
      {
        type: "Box",
        id: "hlcmif",
        classes:
          "flex justify-center items-center w-12 h-12 bg-blue-600 rounded-xl",
        tag: "div",
        parent: "Cekszq",
      },
      {
        type: "SVG",
        id: "hmyymq",
        classes: "w-6 h-6 text-white",
        svg: '<svg class="w-6 h-6 text-white" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">\t\t\t\t\t<path d="M9.465 10H12a2 2 0 1 1 0 4H9.465c.34-.588.535-1.271.535-2 0-.729-.195-1.412-.535-2z"></path>\t\t\t\t\t<path d="M6 15a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm0 1a4 4 0 1 1 0-8 4 4 0 0 1 0 8zm.535-10a3.975 3.975 0 0 1-.409-1H4a1 1 0 0 1 0-2h2.126c.091-.355.23-.69.41-1H4a2 2 0 1 0 0 4h2.535z"></path>\t\t\t\t\t<path d="M14 4a4 4 0 1 1-8 0 4 4 0 0 1 8 0z"></path>\t\t\t\t</svg>',
        parent: "hlcmif",
      },
      {
        type: "Box",
        id: "uelsCm",
        classes: "mt-5",
        tag: "div",
        parent: "Cekszq",
      },
      {
        type: "Heading",
        id: "jekcud",
        classes:
          "group-hover:text-gray-600 text-lg font-semibold text-gray-800 dark:text-white dark:group-hover:text-gray-400",
        content: "Self-service",
        level: 3,
        parent: "uelsCm",
      },
      {
        type: "Text",
        id: "esfgvj",
        classes: "mt-1 text-gray-600 dark:text-gray-400",
        content:
          "Customers can seamlesly manage their subscriptions within your existing store",
        parent: "uelsCm",
      },
      {
        type: "Link",
        id: "wzvgnc",
        classes:
          "group flex flex-col justify-center hover:bg-gray-50 rounded-xl p-4 md:p-7 dark:hover:bg-slate-800",
        link: "#",
        parent: "seiswn",
      },
      {
        type: "Box",
        id: "crtCty",
        classes:
          "flex justify-center items-center w-12 h-12 bg-blue-600 rounded-xl",
        tag: "div",
        parent: "wzvgnc",
      },
      {
        type: "SVG",
        id: "wtoekA",
        classes: "w-6 h-6 text-white",
        svg: '<svg class="w-6 h-6 text-white" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">\t\t\t\t\t<path d="M5.5 7a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zM5 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5z"></path>\t\t\t\t\t<path d="M9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.5L9.5 0zm0 1v2A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z"></path>\t\t\t\t</svg>',
        parent: "crtCty",
      },
      {
        type: "Box",
        id: "zzpztt",
        classes: "mt-5",
        tag: "div",
        parent: "wzvgnc",
      },
      {
        type: "Heading",
        id: "kbnopq",
        classes:
          "group-hover:text-gray-600 text-lg font-semibold text-gray-800 dark:text-white dark:group-hover:text-gray-400",
        content: "Flexible terms",
        level: 3,
        parent: "zzpztt",
      },
      {
        type: "Text",
        id: "xviBch",
        classes: "mt-1 text-gray-600 dark:text-gray-400",
        content:
          "We support any number of days, weeks and months as billing schedules",
        parent: "zzpztt",
      },
      {
        type: "Box",
        id: "Djsjmk",
        classes: "bg-white dark:bg-gray-900",
        tag: "div",
        parent: null,
      },
      {
        type: "Box",
        id: "bxjbfj",
        classes: "container px-6 py-8 mx-auto",
        tag: "div",
        parent: "Djsjmk",
      },
      {
        type: "Box",
        id: "rDmitj",
        classes: "xl:items-center xl:-mx-8 xl:flex",
        tag: "div",
        parent: "bxjbfj",
      },
      {
        type: "Box",
        id: "CDkifh",
        classes: "flex flex-col items-center xl:items-start xl:mx-8",
        tag: "div",
        parent: "rDmitj",
      },
      {
        type: "Heading",
        id: "geingd",
        classes:
          "text-3xl font-medium text-gray-800 capitalize lg:text-4xl dark:text-white",
        content: "Our Pricing Plan",
        level: 1,
        parent: "CDkifh",
      },
      {
        type: "Box",
        id: "eBmAqv",
        classes: "mt-4",
        tag: "div",
        parent: "CDkifh",
      },
      {
        type: "EmptyBox",
        id: "yvCpbv",
        classes: "inline-block w-40 h-1 rounded-full bg-primary-500",
        baseClasses: "",
        dynamicClasses: "",
        parent: "eBmAqv",
      },
      {
        type: "EmptyBox",
        id: "kbcigx",
        classes: "inline-block w-3 h-1 mx-1 rounded-full bg-primary-500",
        baseClasses: "",
        dynamicClasses: "",
        parent: "eBmAqv",
      },
      {
        type: "EmptyBox",
        id: "jezhff",
        classes: "inline-block w-1 h-1 rounded-full bg-primary-500",
        baseClasses: "",
        dynamicClasses: "",
        parent: "eBmAqv",
      },
      {
        type: "Text",
        id: "xtbpBf",
        classes: "mt-4 font-medium text-gray-500 dark:text-gray-300",
        content: "Simple, transparent billing",
        parent: "CDkifh",
      },
      {
        type: "Box",
        id: "DCxvxt",
        classes: "flex-1 xl:mx-8",
        tag: "div",
        parent: "rDmitj",
      },
      {
        type: "Box",
        id: "Adqvjy",
        classes:
          "mt-8 space-y-8 md:-mx-4 md:flex md:items-center md:justify-center md:space-y-0 xl:mt-0",
        tag: "div",
        parent: "DCxvxt",
      },
      {
        type: "Box",
        id: "Dhlapz",
        classes:
          "max-w-sm mx-auto border rounded-lg md:mx-4 dark:border-gray-700",
        tag: "div",
        parent: "Adqvjy",
      },
      {
        type: "Box",
        id: "iijvDt",
        classes: "p-6",
        tag: "div",
        parent: "Dhlapz",
      },
      {
        type: "Heading",
        id: "lumDrc",
        classes:
          "text-xl font-medium text-gray-700 capitalize lg:text-3xl dark:text-white",
        content: "Early Bird",
        level: 1,
        parent: "iijvDt",
      },
      {
        type: "Text",
        id: "jsybpC",
        classes: "mt-4 text-gray-500 dark:text-gray-300",
        content: "Enough for most small to medium businesses",
        parent: "iijvDt",
      },
      {
        type: "Heading",
        id: "vowebc",
        classes:
          "mt-4 text-2xl font-medium text-gray-700 sm:text-4xl dark:text-gray-300",
        level: 2,
        parent: "iijvDt",
      },
      {
        type: "Span",
        id: "ocCkfn",
        content: "R100",
        parent: "vowebc",
      },
      {
        type: "Span",
        id: "aoyaCq",
        classes: "text-base font-medium",
        content: "/Month",
        parent: "vowebc",
      },
      {
        type: "Text",
        id: "oqcwbv",
        classes: "mt-1 text-gray-500 dark:text-gray-300",
        content: "Monthly payment",
        parent: "iijvDt",
      },
      {
        type: "Link",
        id: "abcloi",
        classes: "inline-flex w-full justify-centertext-center text-center",
        parent: "iijvDt",
        link: "mailto:wattpay@develocks.dev",
      },
      {
        type: "Span",
        classes:
          "w-full px-4 py-2 mt-6 tracking-wide text-white capitalize transition-colors duration-300 transform rounded-md focus:outline-none focus:bg-blue-500 focus:ring focus:ring-blue-300 focus:ring-opacity-80 bg-primary-500 hover:bg-primary-700",
        id: "olfove",
        content: "Get Started Today",
        parent: "abcloi",
      },
      {
        type: "Line",
        id: "zzxumg",
        classes: "border-gray-200 dark:border-gray-700",
        parent: "Dhlapz",
      },
      {
        type: "Box",
        id: "cikDms",
        classes: "p-6",
        tag: "div",
        parent: "Dhlapz",
      },
      {
        type: "Heading",
        id: "pwqkuy",
        classes:
          "text-lg font-medium text-gray-700 capitalize lg:text-xl dark:text-white",
        content: "What’s included:",
        level: 1,
        parent: "cikDms",
      },
      {
        type: "Box",
        id: "gsBwct",
        classes: "mt-8 space-y-4",
        tag: "div",
        parent: "cikDms",
      },
      {
        type: "Box",
        id: "kwhbca",
        classes: "flex items-center",
        tag: "div",
        parent: "gsBwct",
      },
      {
        type: "SVG",
        id: "elAswy",
        classes: "w-5 h-5 text-primary-500",
        svg: '<svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 text-blue-500" viewBox="0 0 20 20" fill="currentColor"> <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" /> </svg>',
        baseClasses: "",
        dynamicClasses: "",
        parent: "kwhbca",
      },
      {
        type: "Span",
        id: "awbhgr",
        classes: "mx-4 text-gray-700 dark:text-gray-300",
        content: "100 orders (overage billed at R1/transaction)",
        parent: "kwhbca",
      },
      {
        type: "Box",
        id: "ptjikw",
        classes: "flex items-center",
        tag: "div",
        parent: "gsBwct",
      },
      {
        type: "SVG",
        id: "Byphjc",
        classes: "w-5 h-5 text-primary-500",
        svg: '<svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 text-blue-500" viewBox="0 0 20 20" fill="currentColor"> <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" /> </svg>',
        baseClasses: "",
        dynamicClasses: "",
        parent: "ptjikw",
      },
      {
        type: "Span",
        id: "Dyjqmg",
        classes: "mx-4 text-gray-700 dark:text-gray-300",
        content: "100 new subscriptions",
        parent: "ptjikw",
      },
      {
        type: "Box",
        id: "ubtyhy",
        classes:
          "max-w-sm mx-auto border rounded-lg md:mx-4 dark:border-gray-700",
        tag: "div",
        parent: "Adqvjy",
      },
      {
        type: "Box",
        id: "qrfmxu",
        classes: "p-6",
        tag: "div",
        parent: "ubtyhy",
      },
      {
        type: "Heading",
        id: "vAncDi",
        classes:
          "text-xl font-medium text-gray-700 capitalize lg:text-3xl dark:text-white",
        content: "Bespoke",
        level: 1,
        parent: "qrfmxu",
      },
      {
        type: "Text",
        id: "znhilD",
        classes: "mt-4 text-gray-500 dark:text-gray-300",
        content:
          "If you have specific flows or integration requirements, we can help",
        parent: "qrfmxu",
      },
      {
        type: "Heading",
        id: "uCBwzt",
        classes:
          "mt-4 text-2xl font-medium text-gray-700 sm:text-4xl dark:text-gray-300",
        level: 2,
        parent: "qrfmxu",
      },
      {
        type: "Text",
        id: "ilatBq",
        classes: "mt-1 text-gray-500 dark:text-gray-300",
        content: "Enter text",
        parent: "qrfmxu",
      },
      {
        type: "Link",
        id: "abcloo",
        classes: "inline-flex w-full justify-centertext-center text-center",
        parent: "qrfmxu",
        link: "mailto:wattpay@develocks.dev",
      },
      {
        type: "Span",
        id: "pBCkdz",
        classes:
          "w-full px-4 py-2 mt-6 tracking-wide text-white capitalize transition-colors duration-300 transform rounded-md focus:outline-none focus:bg-blue-500 focus:ring focus:ring-blue-300 focus:ring-opacity-80 bg-primary-500 hover:bg-primary-700",
        content: "Let's talk",
        parent: "abcloo",
      },
      {
        type: "Line",
        id: "wwDwCd",
        classes: "border-gray-200 dark:border-gray-700",
        parent: "ubtyhy",
      },
      {
        type: "Box",
        id: "icnoow",
        classes: "max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto",
        tag: "div",
        parent: null,
      },
      {
        type: "Box",
        id: "fCecmC",
        classes: "max-w-2xl mx-auto mb-10 lg:mb-14",
        tag: "div",
        parent: "icnoow",
      },
      {
        type: "Heading",
        id: "eAocux",
        classes:
          "text-2xl font-bold md:text-4xl md:leading-tight dark:text-white",
        content: "You might be wondering...",
        level: 2,
        parent: "fCecmC",
      },
      {
        type: "Box",
        id: "nfguhC",
        classes:
          "max-w-2xl mx-auto divide-y divide-gray-200 dark:divide-gray-700",
        tag: "div",
        parent: "icnoow",
      },
      {
        type: "Box",
        id: "elCkne",
        classes: "py-8 first:pt-0 last:pb-0",
        tag: "div",
        parent: "nfguhC",
      },
      {
        type: "Box",
        id: "aBbexr",
        classes: "flex gap-x-5",
        tag: "div",
        parent: "elCkne",
      },
      {
        type: "SVG",
        id: "imefBB",
        classes: "flex-shrink-0 mt-1 w-6 h-6 text-gray-500",
        svg: '<svg class="flex-shrink-0 mt-1 w-6 h-6 text-gray-500" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">\t\t\t\t\t<path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>\t\t\t\t\t<path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"></path>\t\t\t\t</svg>',
        parent: "aBbexr",
      },
      {
        type: "Box",
        id: "llvxgt",
        classes: "",
        tag: "div",
        parent: "aBbexr",
      },
      {
        type: "Heading",
        id: "wreuxz",
        classes: "md:text-lg font-semibold text-gray-800 dark:text-gray-200",
        content: "What payment gateways can I use?",
        level: 3,
        parent: "llvxgt",
      },
      {
        type: "Text",
        id: "hhudyt",
        classes: "mt-1 text-gray-500",
        content:
          "We currently support PayStack. If you are using an alternative payment gateway, feel free to reach out",
        parent: "llvxgt",
      },
      {
        type: "Box",
        id: "egziqg",
        classes: "py-8 first:pt-0 last:pb-0",
        tag: "div",
        parent: "nfguhC",
      },
      {
        type: "Box",
        id: "xqAsdg",
        classes: "flex gap-x-5",
        tag: "div",
        parent: "egziqg",
      },
      {
        type: "SVG",
        id: "sjbAhi",
        classes: "flex-shrink-0 mt-1 w-6 h-6 text-gray-500",
        svg: '<svg class="flex-shrink-0 mt-1 w-6 h-6 text-gray-500" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">\t\t\t\t\t<path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>\t\t\t\t\t<path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"></path>\t\t\t\t</svg>',
        parent: "xqAsdg",
      },
      {
        type: "Box",
        id: "hvsmod",
        classes: "",
        tag: "div",
        parent: "xqAsdg",
      },
      {
        type: "Heading",
        id: "aChqac",
        classes: "md:text-lg font-semibold text-gray-800 dark:text-gray-200",
        content: "What changes can customers make?",
        level: 3,
        parent: "hvsmod",
      },
      {
        type: "Text",
        id: "dxjfmu",
        classes: "mt-1 text-gray-500",
        content:
          "Customers can update their card details, change their recurring basket contents and update their shipping address.",
        parent: "hvsmod",
      },
      {
        type: "Box",
        id: "zwplhi",
        classes: "py-8 first:pt-0 last:pb-0",
        tag: "div",
        parent: "nfguhC",
      },
      {
        type: "Box",
        id: "BqcCov",
        classes: "flex gap-x-5",
        tag: "div",
        parent: "zwplhi",
      },
      {
        type: "SVG",
        id: "ebDwAt",
        classes: "flex-shrink-0 mt-1 w-6 h-6 text-gray-500",
        svg: '<svg class="flex-shrink-0 mt-1 w-6 h-6 text-gray-500" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">\t\t\t\t\t<path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>\t\t\t\t\t<path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"></path>\t\t\t\t</svg>',
        parent: "BqcCov",
      },
      {
        type: "Box",
        id: "DiCxem",
        classes: "",
        tag: "div",
        parent: "BqcCov",
      },
      {
        type: "Heading",
        id: "rhBqhp",
        classes: "md:text-lg font-semibold text-gray-800 dark:text-gray-200",
        content: "How secure is WattPay?",
        level: 3,
        parent: "DiCxem",
      },
      {
        type: "Text",
        id: "xbCnwp",
        classes: "mt-1 text-gray-500",
        content:
          "We do not store any PCI information. We simply retain the payment token which is unique to your merchant account and use this to drive billing.",
        parent: "DiCxem",
      },
      {
        type: "Box",
        id: "jCCwpm",
        classes: "py-8 first:pt-0 last:pb-0",
        tag: "div",
        parent: "nfguhC",
      },
      {
        type: "Box",
        id: "vzuouD",
        classes: "flex gap-x-5",
        tag: "div",
        parent: "jCCwpm",
      },
      {
        type: "SVG",
        id: "dhsbtj",
        classes: "flex-shrink-0 mt-1 w-6 h-6 text-gray-500",
        svg: '<svg class="flex-shrink-0 mt-1 w-6 h-6 text-gray-500" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">\t\t\t\t\t<path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>\t\t\t\t\t<path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"></path>\t\t\t\t</svg>',
        parent: "vzuouD",
      },
      {
        type: "Box",
        id: "zvBykr",
        classes: "",
        tag: "div",
        parent: "vzuouD",
      },
      {
        type: "Heading",
        id: "jCaChk",
        classes: "md:text-lg font-semibold text-gray-800 dark:text-gray-200",
        content: "What payment methods does WattPay support?",
        level: 3,
        parent: "zvBykr",
      },
      {
        type: "Text",
        id: "bptcwk",
        classes: "mt-1 text-gray-500",
        content:
          "This is highly dependant on your payment gateway. Currently, we support card payments via PayStack",
        parent: "zvBykr",
      },
      {
        id: "ocxlsj",
        type: "Box",
        classes: "text-gray-600 body-font -900 bg-primary-500 md:block",
        parent: null,
        tag: "section",
        tagId: "CTA 2",
        pre_block_id: "76hsk7n7p5",
      },
      {
        id: "qeDgxn",
        type: "Box",
        classes:
          "text-center py-10 px-4 bg-primary-500 md:w-10/12 md:mx-auto lg:w-2/3 lg:px-4",
        tag: "div",
        parent: "ocxlsj",
      },
      {
        id: "zfCoAu",
        type: "Heading",
        classes:
          "flex-grow text-2xl font-medium title-font text-center text-white",
        level: 1,
        content: "Start automatically driving your sales",
        parent: "qeDgxn",
      },
      {
        type: "Box",
        tag: "div",
        classes: " mt-5 xl:text-center xl:mt-6",
        backgroundImage: "",
        id: "yzucox",
        tagId: "Button",
        parent: "qeDgxn",
      },
      {
        type: "Link",
        classes:
          "bg-white inline-block px-6 py-3 rounded-global xl:p-3 xl:text-lg xl:font-medium xl:text-current",
        content: "Let's talk",
        link: "mailto:wattpay@develocks.dev",
        id: "fpzcuf",
        parent: "yzucox",
      },
      {
        type: "Box",
        id: "ftabCk",
        classes: "py-12 leading-6 px-4 lg:px-8",
        tag: "footer",
        tagId: "Footer 4",
        pre_block_id: "837syjy3u1",
        parent: null,
      },
      {
        type: "Box",
        id: "wlnBde",
        classes: "flex justify-between mx-auto mb-6 max-w-screen-xl lg:mb-8",
        tag: "div",
        parent: "ftabCk",
      },
      {
        type: "Image",
        url: "https://app.wattpay.co/logo-light.png",
        alt: "",
        classes: "",
        id: "bxalhn",
        parent: "wlnBde",
      },
      {
        type: "Link",
        id: "bbfukh",
        classes: "flex items-center cursor-pointer hover:text-gray-200",
        link: "#",
        parent: "wlnBde",
      },
      {
        type: "Span",
        id: "uxkrgl",
        classes: "",
        content: "Return to top",
        parent: "bbfukh",
      },
      {
        type: "SVG",
        id: "Bfjfgl",
        classes: "block w-4 h-4 align-middle ml-2",
        svgIcon: "FaArrowUp",
        svg: "",
        parent: "bbfukh",
      },
      {
        type: "Box",
        id: "AgpcoA",
        classes:
          "pt-8 mx-auto mt-8 max-w-screen-xl border-t-2 border-solid box-border dark:border-slate-700 lg:mt-12",
        tag: "div",
        parent: "ftabCk",
      },
      {
        type: "Text",
        id: "syeDDl",
        classes: "m-0 text-sm leading-5 text-center md:text-left",
        content: "©2023 Develocks. All rights reserved",
        parent: "AgpcoA",
      },
    ],
    [],
  );

  // @ts-ignore
  return <BlockRenderer blocks={content} />;
  //   return (
  //   <div className="App">
  //     <header className="App-header">
  //       <img src={logo} className="App-logo" alt="logo" />
  //       <p>
  //         Edit <code>src/App.tsx</code> and save to reload.
  //       </p>
  //       <a
  //         className="App-link"
  //         href="https://reactjs.org"
  //         target="_blank"
  //         rel="noopener noreferrer"
  //       >
  //         Learn React
  //       </a>
  //     </header>
  //     <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSdKkNjAC77wbhSqAlyrsKSWfbFLezTcuoWapGqX6yrfuhLo2w/viewform?embedded=true" width="640" height="1297" frameBorder="0" marginHeight="0" marginWidth="0">Loading…</iframe>
  //   </div>
  // );
}

export default App;
